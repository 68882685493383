import { React, useCallback } from 'react';
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

// Applies the SurveyJS default stylesheet
StylesManager.applyTheme('defaultV2');

// TODO: Replace with uid
// const SURVEY_ID = 1;

// The schema to render the survey
const sampleSurveySchema = {
  checkErrorsMode: 'onValueChanging',
  elements: [
    {
      name: 'structuredDataPercentage',
      title:
        'What percentage of your logs are structured data versus flat files?',
      choices: ['Less than 50%', 'About 50%', 'More than 50%'],
      type: 'radiogroup',
      isRequired: true,
      hasComment: true,
      commentText: 'Additional Comments',
    },
    {
      name: 'errorMessagesHelpful',
      title: 'What percent of your application’s error messages are helpful?',
      choices: ['Less than 50%', 'About 50%', 'More than 50%'],
      type: 'radiogroup',
      isRequired: true,
      hasComment: true,
      commentText: 'Additional Comments',
    },
  ],
};

function SurveyExample() {
  const survey = new Model(sampleSurveySchema);

  const alertResults = useCallback((sender) => {
    const results = JSON.stringify(sender.data);
    alert(results);
    // TODO: Replace with posting to actual endpoint
    // saveSurveyResults(
    //   "https://your-web-service.com/" + SURVEY_ID,
    //   sender.data
    // )
  }, []);

  survey.onComplete.add(alertResults);

  return <Survey model={survey} />;
}

// TODO: Set sample handlers once endpoints are ready
// function saveSurveyResults(url, json) {
//   const request = new XMLHttpRequest();
//   request.open('POST', url);
//   request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
//   request.addEventListener('load', () => {
//     // Handle "load"
//   });
//   request.addEventListener('error', () => {
//     // Handle "error"
//   });
//   request.send(JSON.stringify(json));
// }

export default SurveyExample;
