import React from 'react';
import { NavLink } from 'react-router-dom';

function Nav() {
  // This styling will be applied to a <NavLink> when the
  // route that it links to is currently selected.
  let activeStyle = {
    fontWeight: 'bold',
  };

  return (
    <ul>
      <li>
        <NavLink
          to="/"
          end
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Dashboard
        </NavLink>
      </li>
      <li>
        <NavLink
          to="survey-example"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Example Survey
        </NavLink>
      </li>
      <li>
        <NavLink
          to="teams"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Teams
        </NavLink>
      </li>
    </ul>
  );
}

export default Nav;
