import React from 'react';
import Login from './Login';
import Dashboard from './Dashboard';

// Set to true to view the dashboard
const isLoggedIn = true;

const Homepage = () => {
  if (isLoggedIn) {
    return <Dashboard />;
  }

  return <Login />;
};

export default Homepage;
