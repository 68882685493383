import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { defaultTheme, Provider } from '@adobe/react-spectrum';

import Layout from './Layout';
import Homepage from './pages/Homepage';
import SurveyExample from './examples/SurveyExample';
import Teams from './pages/Teams';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="" element={<Homepage />} />
          <Route path="survey-example" element={<SurveyExample />} />
          <Route path="teams" element={<Teams />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider theme={defaultTheme}>
      <App />
    </Provider>
  </React.StrictMode>
);
