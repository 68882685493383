import React from 'react';
import { Grid, View } from '@adobe/react-spectrum';
import SortingTableExample from '../examples/SortingTableExample';

// Placeholder for a potential Dashboard view
const Dashboard = () => (
  <>
    {/* Grid Example */}
    <Grid
      areas={['sidebar content']}
      columns={['1fr', '3fr']}
      height="size-600"
      gap="size-100"
    >
      <View backgroundColor="blue-600" gridArea="sidebar" />
      <View backgroundColor="purple-600" gridArea="content" />
    </Grid>

    <SortingTableExample />
  </>
);

export default Dashboard;
