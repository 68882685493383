import React from 'react';
import {
  TableView,
  TableHeader,
  Column,
  TableBody,
  Row,
  Cell,
  Flex,
} from '@adobe/react-spectrum';
import { useCollator } from 'react-aria';
import { useAsyncList } from 'react-stately';

function SortingTableExample() {
  let collator = useCollator({ numeric: true });

  let list = useAsyncList({
    async load({ signal }) {
      let res = await fetch(`https://swapi.py4e.com/api/people/?search`, {
        signal,
      });
      let json = await res.json();
      return {
        items: json.results,
      };
    },

    async sort({ items, sortDescriptor }) {
      return {
        items: items.sort((a, b) => {
          let first = a[sortDescriptor.column];
          let second = b[sortDescriptor.column];
          let cmp = collator.compare(first, second);
          if (sortDescriptor.direction === 'descending') {
            cmp *= -1;
          }
          return cmp;
        }),
      };
    },
  });

  return (
    <Flex width="100%" direction="column" gap="size-150">
      <TableView
        aria-label="Example table with client side sorting"
        sortDescriptor={list.sortDescriptor}
        onSortChange={list.sort}
        height="size-3000"
        flex
      >
        <TableHeader>
          <Column key="name" allowsSorting>
            Name
          </Column>
          <Column key="height" allowsSorting>
            Height
          </Column>
          <Column key="mass" allowsSorting>
            Mass
          </Column>
          <Column key="birth_year" allowsSorting>
            Birth Year
          </Column>
        </TableHeader>
        <TableBody items={list.items} loadingState={list.loadingState}>
          {(item) => (
            <Row key={item.name}>
              {(columnKey) => <Cell>{item[columnKey]}</Cell>}
            </Row>
          )}
        </TableBody>
      </TableView>
    </Flex>
  );
}

export default SortingTableExample;
